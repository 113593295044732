import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ServiceProviderUser } from 'src/api/models';
import { loader } from 'src/app/shared/decorators/loader';
import { ProfileFacadeService } from '../../auth/profile/profile-facade.service';

@Component({
  selector: 'app-workingdaypopup',
  templateUrl: './workingdaypopup.page.html',
  styleUrls: ['./workingdaypopup.page.scss'],
})
export class WorkingdaypopupPage implements OnInit {

  monToggle = false;
  tueToggle = false;
  wedToggle = false;
  thuToggle = false;
  friToggle = false;
  satToggle = false;
  sunToggle = false;
  is_start_of_week_sunday = false;
  user: ServiceProviderUser;

  constructor(public profileFacadeService: ProfileFacadeService, private modelCtrl: ModalController) { }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.getData()

  }


  // @loader()
  async getData() {
    // this.user = await this.profileFacadeService.getUserInfo() as ServiceProviderUser;

    // this.monToggle = this.user.profile.is_monday;
    // this.tueToggle = this.user.profile.is_tuesday;
    // this.wedToggle = this.user.profile.is_wednesday;
    // this.thuToggle = this.user.profile.is_thursday;
    // this.friToggle = this.user.profile.is_friday;
    // this.satToggle = this.user.profile.is_saturday;
    // this.sunToggle = this.user.profile.is_sunday;


  }

  async update() {
    this.modelCtrl.dismiss({ "mon": this.monToggle, "tue": this.tueToggle, "wed": this.wedToggle, "thu": this.thuToggle, "fri": this.friToggle, "sat": this.satToggle, "sun": this.sunToggle });
  }

  back() {
    this.modelCtrl.dismiss();
  }



}
