import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.page.html',
  styleUrls: ['./image-preview.page.scss'],
})
export class ImagePreviewPage implements OnInit {

  img = "";

  constructor(private modelCtrl: ModalController) { }

  ngOnInit() {
  }

  back() {
    this.modelCtrl.dismiss();
  }

  send() {
    this.modelCtrl.dismiss({ "send": true, });
  }

}
