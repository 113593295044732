import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-completedetailpopup',
  templateUrl: './completedetailpopup.page.html',
  styleUrls: ['./completedetailpopup.page.scss'],
})
export class CompletedetailpopupPage implements OnInit {

  fromBankDetail;

  constructor(public router: Router, private modelCtrl: PopoverController) { }

  ngOnInit() {
  }

  update() {


    if (`${this.fromBankDetail}` == "null" || `${this.fromBankDetail}` == "undefined") {
      this.modelCtrl.dismiss();
      this.router.navigate(['/company/company-detail']);
    }
    else {
      this.modelCtrl.dismiss();
      this.router.navigate(['/technicians/bankdetail']);
    }

  }

}
